<template>
  <div class="flex flex-wrap mt-4">
    <span
      v-for="(row, i) in rows"
      :key="row.id"
      class="flex px-3 py-2 rounded-md bg-primary text-white mr-2 mb-2"
    >
      <span class="max-w-[200px] truncate">
        {{ row.parent ? row.parent.name + '-' : '' }}{{ row.name }}</span
      >

      <a href="#" @click.prevent="$emit('remove', i)"
        ><font-awesome-icon :icon="['fas', 'times']"
      /></a>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['remove'],
};
</script>
