import Subject from '@/api/Subject';
import { ref, computed, reactive, watch, nextTick, shallowReactive } from 'vue';
import _, { cloneDeep } from 'lodash';
import useComputed from '../useComputed';

export default ({ part, chapter, section, id, emit }) => {
  const optsPart = reactive([]);
  const optsChapter = reactive([]);
  const optsSection = reactive([]);

  const fetcher = () => {
    Subject.scope({ sbjKey: id.value }).then((res) => {
      if (!res.data || !res.data.rows) return;
      res.data.rows.forEach((part) => {
        optsPart.push({
          type: 'part',
          id: part.id,
          name: part.name,
          count: part.chapter ? part.chapter.length : 0,
        });

        if (part.chapter) {
          part.chapter.forEach((chapter) => {
            optsChapter.push({
              type: 'chapter',
              pid: part.id,
              id: chapter.id,
              name: chapter.name,
              count: chapter.section ? chapter.section.length : 0,
            });

            if (chapter.section) {
              chapter.section.forEach((section) => {
                optsSection.push({
                  type: 'section',
                  pid: part.id,
                  cid: chapter.id,
                  id: section.id,
                  name: section.name,
                });
              });
            }
          });
        }
      });
    });
  };

  const form = shallowReactive({
    part: useComputed(part, emit, optsPart, 'update:part'),
    chapter: useComputed(chapter, emit, optsChapter, 'update:chapter'),
    section: useComputed(section, emit, optsSection, 'update:section'),
  });

  watch(
    id,
    (val, old) => {
      optsPart.length = 0;
      optsChapter.length = 0;
      optsSection.length = 0;
      if (val) {
        fetcher();
      }
    },
    {
      immediate: true,
    },
  );

  const parts = computed(() => {
    return optsPart.map((x) => {
      return {
        name: x.name,
        id: x.id,
      };
    });
  });

  watch(parts, (val) => {
    form.part.value = form.part.value.filter(
      (x) => val.findIndex((y) => y.id === x) > -1,
    );
  });

  const chapters = computed(() => {
    return (
      optsChapter &&
      optsChapter.filter((x) => form.part.value.indexOf(x.pid) > -1)
    );
  });
  watch(
    chapters,
    (val) => {
      nextTick(() => {
        console.log('chapters watch');
        form.chapter.value = form.chapter.value.filter((x) => {
          return val.findIndex((y) => y.id === x) > -1;
        });
      });
    },
    { deep: true },
  );

  const sections = computed(() => {
    return optsSection.filter((x) => form.part.value.indexOf(x.pid) > -1);
  });

  watch(
    sections,
    (val) => {
      nextTick(() => {
        form.section.value = form.section.value.filter((x) => {
          return val.findIndex((y) => y.id === x) > -1;
        });
      });
    },
    { deep: true },
  );

  const selectAllChapter = () => {
    const _section = [];
    const _chapter = [];

    optsSection.forEach((x) => {
      if (form.part.value.includes(x.pid)) {
        _section.push(x.id);
      }
    });
    optsChapter.forEach((x) => {
      if (form.part.value.includes(x.pid)) {
        _chapter.push(x.id);
      }
    });

    form.section.value = _section;
    form.chapter.value = _chapter;
  };

  const cancelAllChapter = () => {
    form.section.value = [];
    form.chapter.value = [];
  };

  return {
    form,
    parts,
    chapters,
    sections,
    selectAllChapter,
    cancelAllChapter,
  };
};
