<template>
  <Tour v-if="show === true" :rows="rows" @onEnd="done(key)"></Tour>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue';
import Tour from '@/modules/tour/Index.vue';

const key = 'sbj';
const { done, status } = inject('tour');

const rows = [
  {
    target: '[data-v-step="1"]',
    header: {
      title: '選擇想加強的科目',
    },
    content: `一次只能選擇一個科目重點加強。`,
    params: {
      enableScrolling: false,
      placement: 'top-start',
    },
  },
  {
    target: '[data-v-step="range"]',
    header: {
      title: '選擇各項篩選條件',
    },
    content: `多維度交叉篩選，找到真的想加強的題目。`,
    params: {
      enableScrolling: false,
      placement: 'top-start',
    },
  },
  {
    target: '[data-v-step="2"]',
    header: {
      title: '加入篩選條件',
    },
    content: `點擊”加入篩選條件”將選好的條件加入。`,
  },
  {
    target: '[data-v-step="3"]',
    header: {
      title: '顯示符合篩選結果的試題',
    },
    content: `確認篩選條件正確後，點擊”顯示試題”出現所有符合條件的試題列表。`,
  },
];

const show = ref(null);
onMounted(() => {
  show.value = status[key];
});
</script>

<style lang="sass" scoped>
:deep(.v-step)
  &::after
    background-image: url(~@/assets/imgs/analysisRole5.png)
</style>
