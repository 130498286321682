<template>
  <div>
    <El-Button :size="size" :type="selected ? btnType : ''" @click="toggle">
      {{ text }}
    </El-Button>
  </div>
</template>
<script>
import { toRefs, computed } from 'vue';

export default {
  components: {},
  props: {
    id: {
      type: [String, Number],
      default: null,
    },

    size: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },

    btnType: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, id } = toRefs(props);
    const toggle = () => {
      const idx = modelValue.value.indexOf(id.value);

      let _chapter = [...modelValue.value];
      if (idx < 0) {
        _chapter.push(id.value);
      } else {
        _chapter.splice(idx, 1);
      }

      emit('update:modelValue', [..._chapter]);
    };

    const selected = computed(() => {
      return modelValue.value.indexOf(id.value) > -1;
    });

    return { selected, toggle };
  },
};
</script>

<style lang="sass" scoped>
.theme-primary
  --el-color-primary: #003B8B
</style>
