import { reactive } from 'vue';

export default (key) => {
  const opts = reactive([
    {
      id: 'all',
      name: '不拘',
    },
    {
      id: '1',
      name: '答對',
    },
    {
      id: '2',
      name: '答錯',
    },
    {
      id: '3',
      name: '尚未演練',
    },
  ]);

  return { opts };
};
