<template>
  <div>
    <h2 class="sub-title">{{ title }}</h2>
    <!-- 只有select 可以直接綁定物件作為value -->

    <div class="flex">
      <el-select
        v-model="parent"
        filterable
        remote
        clearable
        :reserve-keyword="false"
        :placeholder="pph"
        value-key="id"
      >
        <el-option
          v-for="row in parents"
          :key="row.id"
          :label="row.name"
          :value="row"
        />
      </el-select>

      <el-select
        v-model="child"
        class="ml-2"
        :disabled="!parent"
        filterable
        clearable
        remote
        value-key="id"
        :reserve-keyword="false"
        :placeholder="cph"
      >
        <el-option
          v-for="row in children"
          :key="row.id"
          :label="row.name"
          :disabled="childModel.findIndex((x) => row.id === x.id) > -1"
          :value="row"
        />
      </el-select>

      <El-Button class="ml-2" :disabled="disabled" @click="add()"
        >新增</El-Button
      >
    </div>
    <Results :rows="results" @remove="remove"></Results>
  </div>
</template>
<script>
import { ElSelect, ElOption } from 'element-plus';
import Results from './_Results.vue';
import { computed, reactive, ref, watch } from 'vue';
import useOpts from './useOpts';
import _, { result } from 'lodash';

export default {
  components: {
    ElSelect,
    ElOption,
    Results,
  },
  props: {
    title: {
      type: String,
      default: '標題',
    },
    type: {
      type: String,
      default: 'act',
    },

    pph: {
      type: String,
      default: '例',
    },
    cph: {
      type: String,
      default: '例',
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    childModelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue', 'update:childModelValue'],
  setup(props, { emit }) {
    const results = reactive([]);
    const parent = useOpts({
      type: props.type,
    });
    parent.fetcher();

    const child = useOpts({
      type: props.type,
    });

    watch(parent.val, (val) => {
      if (!val) return;
      child.fetcher(val.id);
      child.val.value = null;
    });

    const model = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const childModel = computed({
      get() {
        return props.childModelValue;
      },
      set(val) {
        emit('update:childModelValue', val);
      },
    });

    const reset = () => {
      parent.val.value = null;
      child.val.value = null;
      child.rows.splice(0, child.rows.length);
    };

    const selected = computed(() => {
      return child.val?.value
        ? { ...child.val.value, parent: { ...parent.val.value } }
        : { ...parent.val.value };
    });
    const disabled = computed(() => {
      return results.some((x) => {
        return (
          x.id === selected.value.id && !selected.value.parent && !x.parent
        );
      });
    });

    const add = (val) => {
      let _selected = val ? val : selected.value ? selected.value : null;

      if (!_selected) return;
      let _results = _.cloneDeep(results);

      _results = _selected.parent
        ? _results.filter((x) => x.parent || _selected.parent.id != x.id)
        : _results.filter((x) => _selected.id != x.parent?.id);
      _results.push(_selected);

      results.splice(0, results.length, ..._results);

      update(_.cloneDeep(results));
      reset();
    };

    const remove = (idx) => {
      results.splice(idx, 1);
      update(_.cloneDeep(results));
    };

    const update = (val) => {
      const _parent = val.filter((x) => !x.parent);

      model.value = _parent;

      const _child = val.filter((x) => x.parent);

      childModel.value = _child.map((x) => {
        return { id: x.id, name: x.parent.name + ' ' + x.name };
      });
    };

    // 清空專用
    watch(
      [() => props.modelValue, () => props.childModelValue],
      ([parent, child]) => {
        if (!parent.length && !child.length) {
          results.splice(0, results.length);
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    return {
      parents: parent.rows,
      children: child?.rows,
      parent: parent.val,
      child: child?.val,
      results,
      disabled,
      selected,
      add,
      remove,
      model,
      childModel,
    };
  },
};
</script>
