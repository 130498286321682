import { reactive } from 'vue';
// 8 / 8) 必填 / 選項 / 50題 / 100題 / 150題 / 200題 / 250題 / 預設50題 / 總題數上限1, 000題。
export default () => {
  const opts = reactive([
    {
      key: '50',
      name: '50',
    },
    {
      key: '100',
      name: '100',
    },
    {
      key: '150',
      name: '150',
    },
    {
      key: '200',
      name: '200',
    },
    {
      key: '250',
      name: '250',
    },
    {
      key: 'custom',
      name: '自訂',
    },
  ]);
  return { opts };
};
