import { reactive, inject } from 'vue';

export default (key) => {
  const { confOpts } = inject('store');

  const opts = reactive([
    {
      id: 'all',
      name: '不拘',
    },
    ...confOpts,
  ]);

  return { opts };
};
