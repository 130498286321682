import { reactive, watch } from 'vue';
import Question from '@/api/Question';

export default (key) => {
  const opts = reactive([
    {
      id: 'all',
      name: '不拘',
    },
  ]);

  const fetcher = () => {
    Question.qTypes().then((res) => {
      if (res.code === 1) {
        opts.splice(1, opts.length, ...res.data.rows);
      } else {
        opts.splice(1, opts.length);
      }
    });
  };

  return { opts, fetcher };
};
