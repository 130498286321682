<template>
  <div>
    <h2 class="sub-title">題型(複選)</h2>
    <Checkbox-Input v-model="model" :opts="opts"></Checkbox-Input>
  </div>
</template>

<script>
import { toRefs, watch, toRaw } from 'vue';

import useComputed from '../useComputed';
import useOpts from './useOpts';
import CheckboxInput from '@/components/form/checkboxInput/Index';
import _ from 'lodash';
export default {
  components: {
    CheckboxInput,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, sbjKey } = toRefs(props);

    const { opts, fetcher } = useOpts(sbjKey);
    const model = useComputed(modelValue, emit, opts);
    fetcher();
    return { model, opts };
  },
};
</script>
