<template>
  <div>
    <h2 class="sub-title">信心狀態(複選)</h2>
    <Checkbox-Input v-model="model" :opts="opts"></Checkbox-Input>
  </div>
</template>

<script>
import { toRefs } from 'vue';

import useComputed from '../useComputed';
import useOpts from './useOpts';
import CheckboxInput from '@/components/form/checkboxInput/Index';
export default {
  components: {
    CheckboxInput,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const { opts } = useOpts();
    const model = useComputed(modelValue, emit, opts);

    return { model, opts };
  },
};
</script>
