<template>
  <El-Checkbox-Group v-model="model" class="separate">
    <El-Checkbox-Button
      v-for="opt in opts"
      :key="opt.id"
      :label="opt.id"
      @click="check(opt.id)"
    >
      <div class="flex items-center">
        <img v-if="opt.icon" class="w-5 mr-2" :src="opt.icon" alt="" />
        {{ opt.name }}
      </div>
    </El-Checkbox-Button>
  </El-Checkbox-Group>
</template>

<script>
import { ElCheckboxGroup, ElCheckboxButton } from 'element-plus';

import { useVModel } from '@vueuse/core';
export default {
  components: {
    ElCheckboxGroup,
    ElCheckboxButton,
  },
  props: {
    opts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const model = useVModel(props, 'modelValue', emit);

    const check = (key) => {
      const idx = model.value.indexOf('all');
      if (idx > -1 && key !== 'all') {
        model.value.splice(idx, 1);
      } else if (key === 'all') {
        model.value = [key];
      }
    };
    return { model, check };
  },
};
</script>
