import { reactive, ref, watch } from 'vue';
import Common from '@/api/Common';

export default ({ type }) => {
  // const key = ref(type);
  const val = ref(null);
  const rows = reactive([]);
  const children = reactive([]);
  const fetcher = (id = null) => {
    Common.rule({ type, id }).then((res) => {
      if (res.code === 1) {
        rows.splice(0, rows.length, ...res.data.rows);
      } else {
        // ElMessage('項目不存在');
      }
    });
  };

  return { fetcher, rows, val };
};
