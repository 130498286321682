<template>
  <div ref="dropdownRef">
    <El-Badge :value="count" class="item" :hidden="count == 0">
      <El-Button
        :size="size"
        :type="chapter.indexOf(id) > -1 ? btnType : ''"
        @click.stop="toggle"
      >
        {{ text }}
        <font-awesome-icon
          v-if="opts.length"
          :icon="['fas', 'caret-down']"
          class="ml-1"
        />
      </El-Button>
    </El-Badge>

    <div
      v-show="show"
      class="p-4 absolute left-0 bg-white z-10 w-full shadow-2xl rounded border mt-2 theme-primary"
    >
      <El-Checkbox-group
        v-model="selected"
        class="grid grid-cols-3 gap-x-4 gap-y-2 md:grid-cols-1"
      >
        <El-Checkbox
          v-for="opt in opts"
          :key="valueKey ? opt[valueKey] : opt"
          class="!h-auto min-h-[40px] whitespace-pre-wrap items-start"
          size="large"
          :label="valueKey ? opt[valueKey] : opt"
          >{{ valueKey ? opt.name : opt }}</El-Checkbox
        >
      </El-Checkbox-group>

      <div class="flex justify-between pt-2 border-t mt-3">
        <div class="space-x-4">
          <El-Button
            type="text"
            class="text-gray"
            size="small"
            @click="checkAll"
            >全部選擇</El-Button
          >
          <El-Button
            type="text"
            class="text-gray hover:text-danger"
            size="small"
            @click="uncheckAll"
            >全部取消</El-Button
          >
        </div>
        <El-Button type="primary" size="small" @click="toggle()"
          >確定</El-Button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, ref, computed, watch } from 'vue';
import { ElCheckboxGroup, ElCheckbox, ElBadge } from 'element-plus';
import { onClickOutside } from '@vueuse/core';
import useComputed from '@/hook/useComputed';

export default {
  components: {
    ElCheckboxGroup,
    ElCheckbox,
    ElBadge,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    chapter: {
      type: Array,
      default: () => {
        return [];
      },
    },
    size: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    btnType: {
      type: String,
      default: 'primary',
    },
    opts: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue', 'update:chapter'],
  setup(props, { emit }) {
    const show = ref(false);
    const toggle = () => {
      show.value = !show.value;
    };
    const { modelValue, opts, valueKey, chapter, id } = toRefs(props);

    const selected = useComputed(modelValue, emit);

    const uncheckAll = () => {
      const keys = opts.value.map((x) => x[valueKey.value]);
      selected.value = selected.value.filter((item) => !keys.includes(item));
    };

    const checkAll = () => {
      const keys = opts.value.map((x) => x[valueKey.value]);

      selected.value = Array.from(new Set([...keys, ...selected.value]));
    };

    const count = computed(() => {
      return opts.value.filter(
        (x) => selected.value.indexOf(x[valueKey.value]) > -1,
      ).length;
    });

    watch(count, (val, old) => {
      let _chapter = [];

      if (val === 0) {
        _chapter = chapter.value.filter((x) => x !== id.value);

        if (!_chapter) return;
        emit('update:chapter', [..._chapter]);
      } else if (old === 0) {
        if (chapter.value.indexOf(id.value) < 0) {
          _chapter = chapter.value.concat([id.value]);
          if (!_chapter) return;
          emit('update:chapter', [..._chapter]);
        }
      }
    });

    const dropdownRef = ref(null);

    onClickOutside(dropdownRef, () => {
      if (show.value) {
        show.value = false;
      }
    });
    return { selected, count, uncheckAll, checkAll, show, toggle, dropdownRef };
  },
};
</script>

<style lang="sass" scoped>
.theme-primary
  --el-color-primary: #003B8B
</style>
