<template>
  <div>
    <div v-if="parts.length">
      <h2 class="sub-title">PART: (複選)</h2>
      <El-Checkbox-Group v-model="part_" class="separate">
        <El-Checkbox-Button v-for="opt in parts" :key="opt.id" :label="opt.id">
          {{ opt.name }}
        </El-Checkbox-Button>
      </El-Checkbox-Group>
    </div>
    <div v-if="chapters.length" class="flex items-center mt-4">
      <h2 class="sub-title">章: (複選)</h2>
      <div class="flex mb-3 ml-4">
        <El-Button
          type="text"
          class="text-gray"
          size="small"
          @click="selectAllChapter"
          >全部選擇</El-Button
        >
        <El-Button
          type="text"
          class="text-gray hover:text-danger"
          size="small"
          @click="cancelAllChapter"
          >全部取消</El-Button
        >
      </div>
    </div>

    <div v-if="chapters.length" class="relative">
      <div v-for="cha in chapters" :key="cha.id" class="inline-block mr-4 mb-3">
        <Drop-Checkbox
          v-if="cha.count"
          :id="cha.id"
          v-model:chapter="chapter_"
          v-model="section_"
          :text="cha.name"
          :opts="sections.filter((x) => x.cid === cha.id)"
          :value-key="'id'"
          :btn-type="'info'"
        />
        <Checkbox
          v-else
          :id="cha.id"
          v-model="chapter_"
          :text="cha.name"
          :value-key="'id'"
          :btn-type="'info'"
        ></Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';

import { ElCheckboxGroup, ElCheckboxButton, ElButton } from 'element-plus';
import useOpts from './useOpts';
import DropCheckbox from './DropCheckbox';
import Checkbox from './Checkbox';
export default {
  components: {
    ElCheckboxGroup,
    ElCheckboxButton,
    ElButton,
    DropCheckbox,
    Checkbox,
  },
  props: {
    part: {
      type: Array,
      default: () => [],
    },
    chapter: {
      type: Array,
      default: () => [],
    },
    section: {
      type: Array,
      default: () => [],
    },
    sbtKey: {
      type: String,
      default: '',
    },
  },
  emits: ['update:part', 'update:chapter', 'update:section'],
  setup(props, { emit }) {
    const { part, chapter, section, sbtKey } = toRefs(props);

    const {
      parts,
      chapters,
      sections,
      form,
      selectAllChapter,
      cancelAllChapter,
    } = useOpts({
      part,
      chapter,
      section,
      id: sbtKey,
      emit,
    });

    return {
      part_: form.part,
      chapter_: form.chapter,
      section_: form.section,
      parts,
      chapters,
      sections,
      selectAllChapter,
      cancelAllChapter,
    };
  },
};
</script>
