<template>
  <div>
    <h2 class="sub-title">屬性(複選)</h2>
    <Checkbox-Input v-model="model" :opts="opts"></Checkbox-Input>
  </div>
</template>

<script>
import { toRefs, watch, toRaw } from 'vue';

import useComputed from '../useComputed';
import useOpts from './useOpts';
import CheckboxInput from '@/components/form/checkboxInput/Index';
import _ from 'lodash';
export default {
  components: {
    CheckboxInput,
  },
  props: {
    sbjKey: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, sbjKey } = toRefs(props);
    const defaultValue = toRaw(modelValue.value);

    const { opts, fetcher } = useOpts(sbjKey);
    const model = useComputed(modelValue, emit, opts);
    watch(
      sbjKey,
      (val, old) => {
        model.value = [defaultValue[0].id];
        if (val) {
          fetcher();
        } else {
          opts.splice(1, opts.length);
        }
      },
      { immediate: true },
    );
    return { model, opts };
  },
};
</script>
