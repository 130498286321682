<template>
  <div>
    <p class="text-xl pt-16 pb-6 container">
      將海量題庫按「學科」分流，搭配功能強大的超級篩選器，助你十八般武藝樣樣精通!!
    </p>

    <div class="container bg-white shadow py-4 rounded mb-12 filter-theme-info">
      <div class="flex flex-col space-y-3">
        <el-button size="large" type="text" @click="reset">重新填寫 </el-button>
        <hr />
        <!-- form.sbtKey loading? -->
        <SubjectType
          v-model="form.sbtKey"
          required
          :class="isFixSb ? 'pointer-events-none opacity-60' : ''"
        />
        <Subject
          v-model="form.sbjKey"
          :sbt-key="form.sbtKey?.id"
          :class="isFixSb ? 'pointer-events-none opacity-60' : ''"
        />

        <hr class="my-4" />

        <SubjectScope
          v-if="form.sbjKey"
          v-model:part="form.part"
          v-model:chapter="form.chapter"
          v-model:section="form.section"
          :sbt-key="form.sbjKey?.id"
        />
        <!-- <hr v-if="form.sbjKey" class="my-4" /> -->

        <Period v-model="form.period" />
        <ExPeriod v-model="form.exPeriod" />

        <Conf v-model="form.confIndex"></Conf>
        <AnsStatus v-model="form.ansStatus"></AnsStatus>
        <QTypes v-model="form.qType" />
        <Attributes v-model="form.attribute" :sbj-key="form.sbjKey?.id" />
        <hr class="my-4" />

        <Rules
          v-model="form.act"
          v-model:childModelValue="form.actNo"
          type="act"
          pph="'ex:民法'"
          cph="'ex:225-1'"
          title="法規 (複選)"
        ></Rules>

        <Rules
          v-model="form.reg"
          v-model:childModelValue="form.regNo"
          type="reg"
          pph="ex.政府採購法施行細則"
          cph="'ex:5-1'"
          title="命令/行政規則 (複選)"
        ></Rules>

        <Rule
          v-model="form.ar"
          type="ar"
          pph="'ex.台財融（一）字0901000354號"
          title="行政函釋 (複選)"
        ></Rule>
        <Rule
          v-model="form.prec"
          type="prec"
          pph="ex.94 年台上字第 4929 號"
          title="司法解釋判例 (複選)"
        ></Rule>
        <Rule
          v-model="form.judg"
          type="judg"
          pph="ex.最高法院104年度台上字第3222號判決"
          title="司法解釋判決 (複選)"
        ></Rule>
        <Rule
          v-model="form.acc"
          type="acc"
          pph="ex.IFRS9金融工具"
          title="會計公報 (複選)"
        ></Rule>
        <hr class="my-4" />

        <Score v-model="form.avgCorrectRate" />
        <QCnt v-model="form.qCnt" :min="1" />
        <IdName
          v-model:name="form.keyword"
          v-model:id="form.key"
          :id-label="'試題ID'"
          :name-label="'關鍵字'"
        />
        <!-- <Keyword v-model:keyword="form.keyword" /> -->
      </div>
      <div class="flex space-x-6 py-4 justify-center">
        <el-button
          size="large"
          type="text"
          @click="reset({ excludeKeys: isFixSb ? ['sbjKey', 'sbtKey'] : [] })"
          >重新填寫
        </el-button>
        <el-button type="danger" size="large" data-v-step="2" @click="add_"
          >加入篩選條件
        </el-button>
      </div>

      <Results :rows="results" @remove="remove" @clear="clear" />

      <div class="py-6 flex justify-center">
        <El-Button
          :disabled="!results.length"
          size="large"
          type="primary"
          round
          class="text-xl min-w-[200px]"
          data-v-step="3"
          @click="submit"
          >顯示試題</El-Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';

import IdName from './form/IdName.vue';
import SubjectType from './form/subjectType/Index.vue';
// import Keyword from './form/Keyword.vue';
import Subject from './form/subject/Radio.vue';
import SubjectScope from './form/subjectScope/Index.vue';
import Period from './form/period/Index.vue';
import ExPeriod from './form/exPeriod/Index.vue';
import Results from './_Results.vue';
import Rule from './form/_rule/Index.vue';
import Rules from './form/_rule/Multi.vue';
import AnsStatus from './form/ansStatus/Index.vue';
import Conf from './form/conf/Index.vue';

import Score from './form/score/Index.vue';
// import Qcnt from './form/qCnt/Index.vue';
import Attributes from './form/attributes/Index.vue';
import QTypes from './form/qType/Index.vue';

import useFilter from './useFilter';
import QCnt from './form/qCnt/Single.vue';
import { ElButton, ElMessage } from 'element-plus';
import ApiSubject from '@/api/Subject';
import { useRouter, useRoute } from 'vue-router';
import _ from 'lodash';
import { nextTick } from 'process';

export default {
  components: {
    SubjectType,
    Subject,
    SubjectScope,
    Period,
    ExPeriod,
    Conf,
    Attributes,
    AnsStatus,
    Rule,
    Rules,
    QCnt,
    QTypes,
    Score,
    Results,
    ElButton,
    IdName,
  },
  setup(props) {
    const {
      form,
      add,
      remove,
      reset,
      results,
      scopes,
      getFilterId,
      clear,
      isFixSb,
      changed,
    } = useFilter({
      excludeResetKeys: ['sbjKey', 'sbtKey'],
      defaultForm: {
        keyword: null,
        key: null,
        sbtKey: null,
        // sbtKey: { id: 'SBT00000003', name: '商' },
        sbjKey: null,
        part: [],
        chapter: [],
        section: [],
        confIndex: [{ id: 'all', name: '不拘' }],

        ansStatus: [{ id: 'all', name: '不拘' }],
        qType: [{ id: 'all', name: '不拘' }],
        attribute: [{ id: 'all', name: '不拘' }],
        act: [],
        actNo: [],
        reg: [],
        regNo: [],
        ar: [],
        prec: [],
        judg: [],
        acc: [],
        period: { id: 'all', name: '不拘' },
        exPeriod: { id: 'all', name: '不拘' },
        avgCorrectRate: { id: 'all', name: '不拘' },
        qCnt: { id: '50', name: '50' },
      },
    });

    watch(
      scopes,
      (val) => {
        if (!val?.length) return;
        const scope = val[val.length - 1];

        if (scope.sbtKey && scope.sbjKey) {
          form.sbtKey = { ...scope.sbtKey };
          nextTick(() => {
            form.sbjKey = { ...scope.sbjKey };
          });
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    const add_ = () => {
      let valid = true;

      if (!form.sbtKey || !form.sbjKey) {
        valid = false;
      }

      if (!valid) {
        ElMessage({
          message: '請先選擇科目種類/考試科目"',
          center: true,
        });
      } else {
        add();
      }
    };

    const router = useRouter();
    const route = useRoute();
    const submit = async () => {
      let filterId = route.query.filterId;

      if (!filterId || changed.value) {
        const res = await getFilterId({ API: ApiSubject.filterId });
        if (res.code === 1) {
          filterId = res.data.filterId;
        }
      }
      if (!filterId) {
        ElMessage({
          message: '無法取得filter id',
          center: true,
        });
        return;
      }
      router.push({
        name: 'Exam.Question',
        query: {
          filterId: filterId,
          redirectUri: encodeURIComponent(
            `/exam/sbj/filter?filterId=${filterId}`,
          ),
        },
      });
    };

    return {
      form,
      add_,
      remove,
      reset,
      results,
      submit,
      clear,
      isFixSb,
      changed,
    };
  },
};
</script>
