<template>
  <div v-show="opts.length">
    <h2 class="sub-title">
      考試科目<span class="text-danger text-sm ml-1">(必選)</span>
    </h2>
    <transition name="fade">
      <El-Radio-Group v-model="model" class="separate">
        <El-Radio-Button
          v-for="opt in opts"
          :key="opt.id"
          :label="opt.id"
          size="large"
        >
          {{ opt.name }}
        </El-Radio-Button>
      </El-Radio-Group>
    </transition>
  </div>
</template>

<script>
import { watch, toRefs } from 'vue';

import { ElRadioGroup, ElRadioButton } from 'element-plus';
import useComputed from '../useComputed';
import useOpts from './useOpts';

export default {
  components: {
    ElRadioGroup,
    ElRadioButton,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    sbtKey: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, sbtKey } = toRefs(props);

    const { opts } = useOpts(sbtKey);

    const model = useComputed(modelValue, emit, opts);

    watch(
      sbtKey,
      (val, old) => {
        if (val !== old) {
          model.value = null;
        }
      },
      {
        immediate: true,
      },
    );

    return { model, opts };
  },
};
</script>
