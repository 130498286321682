<template>
  <div>
    <h2 class="sub-title">{{ title }}</h2>

    <!-- 只有select 可以直接綁定物件作為value -->
    <div class="flex">
      <el-select
        v-model="parent"
        filterable
        remote
        clearable
        :reserve-keyword="false"
        :placeholder="pph"
        value-key="id"
      >
        <el-option
          v-for="row in parents"
          :key="row.id"
          :disabled="model.some((x) => x.id === row.id)"
          :label="row.name"
          :value="row"
        />
      </el-select>

      <El-Button class="ml-2" @click="add">新增</El-Button>
    </div>

    <results :rows="model" @remove="remove"></results>
  </div>
</template>
<script>
import { ElSelect, ElOption } from 'element-plus';
import { computed, watch } from 'vue';
import Results from './_Results.vue';
import useOpts from './useOpts';
import _ from 'lodash';
export default {
  components: {
    ElSelect,
    ElOption,
    Results,
  },
  props: {
    title: {
      type: String,
      default: '標題',
    },
    type: {
      type: String,
      default: 'act',
    },

    pph: {
      type: String,
      default: '例',
    },

    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const parent = useOpts({
      type: props.type,
    });
    parent.fetcher();

    const reset = () => {
      parent.val.value = null;
    };
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const selected = computed(() => {
      return parent.val.value;
    });

    const add = () => {
      if (!selected.value) return;
      model.value = [...props.modelValue, selected.value];
      reset();
    };

    const remove = (idx) => {
      model.value.splice(idx, 1);
    };

    return {
      parents: parent.rows,
      parent: parent.val,
      selected,
      add,
      remove,
      model,
    };
  },
};
</script>
